export default class Conn {
    static server = {
        name: '//stock.codeguru-pro.com:'
        // name: '//stock.thediamondsmile.rw:'
        // name: 'http://localhost:'
        // name: 'http://test_stockdjuma.megisha.com:'
    };
    static port = {
        
        val: '8093/'
    }
    static basicPath = {
        val: 'codeguru/api'
    }
    static wholePath = {
        name: Conn.server.name + Conn.port.val + Conn.basicPath.val  /*  http://localhost:8089/guru/api  */
    }
    static ReqContentType = 'application/json'
    static LoginToken = {
        'Content-Type': Conn.ReqContentType,
        'Authorization': 'Bearer '
    }
    static GetToken = {
        'Content-Type': Conn.ReqContentType,
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
}
