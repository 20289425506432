import React from 'react'
import Icon from 'react-icons-kit'
import { ic_supervised_user_circle } from 'react-icons-kit/md/ic_supervised_user_circle'
import { dropbox } from 'react-icons-kit/icomoon/dropbox'
import { useEffect } from 'react'
import { useState } from 'react'
import Repository from '../../services/Repository'

import { ic_send as arrowRight } from 'react-icons-kit/md/ic_send'

import { ic_cancel_schedule_send_outline as removed } from 'react-icons-kit/md/ic_cancel_schedule_send_outline'

function Admin() {

    const [userType, setUserType] = useState()
    const [itemsCount, setItemsCount] = useState()
    const [itemsCountIn, setItemsCountIn] = useState()
    const [itemsCountOut, setItemsCountOut] = useState()



    const [top5MovedIn, setTop5MovedIn] = useState([])
    const [top5MovedOut, setTop5MovedOut] = useState([])

    const iconSize = 30
    const dashStyes = {
        fontSize: '16px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        color: '#3d1b06e8'

    }
    const numberLabel = {
        color: 'rgba(13, 135, 54, 0.91)', marginRight: '9px'
    }
    const labelWrapper = {
        backgroundColor: 'rgba(238, 255, 243, 0.91)',
        boxShadow: '0px 0px 5px #230d02;',
        padding: '15px'
    }

    const mostMOvedStyles = {
        backgroundColor: 'rgba(226, 189, 179, 0.91)',
        boxShadow: '0px 0px 5px #230d02;',
        padding: '15px',
        fontWeight: 'bolder',
        color: 'green'
    }
    const mostMOvedOutStyles = {
        backgroundColor: '#9fb8e6',
        boxShadow: '0px 0px 5px #230d02;',
        padding: '15px',
        fontWeight: 'bolder',
        color: '#000'
    }
    useEffect(() => {
        //Get Token and catname
        let Mdl_SearchItemDate_itemName = {
            startDate: '',
            endDate: ''

        }
        const type = localStorage.getItem('catname')
        if (type) {
            setUserType(localStorage.getItem('catname'))
        }
        Repository.findItemsCount().then((res) => {
            setItemsCount(res.data)
            console.log(res.data)
        })


        Repository.countWmvntByType(Mdl_SearchItemDate_itemName).then(res => {
            setItemsCountIn(res.data.TotalIn)
            setItemsCountOut(res.data.TotalOut)
        })


        //most moved
        Repository.findMostMoved(Mdl_SearchItemDate_itemName).then(res => {
            setTop5MovedIn(res.data.Top5MovedIn)
            setTop5MovedOut(res.data.Top5MovedOut)
        })


    }, [])

    return (
        <div>
            <div className='container'>
                <h1 style={{ textTransform: 'capitalize', fontWeight: 'bolder', padding: '10px', fontSize: '20px' }}>{localStorage.getItem('catname')} Dashboard</h1>
                <hr />

                <div className='row'>
                    <div className='col-4'>
                        <div className='row mt-2 border' style={labelWrapper}>
                            <div className='col-2'>
                                <Icon style={{ color: '#e66707e8' }} size={iconSize} icon={ic_supervised_user_circle} />
                            </div>
                            <div style={dashStyes} className='col-8 d-flex align-items-center'><span style={numberLabel}>3 </span> System  Users </div>
                        </div>

                        <div className='row mt-2 border' style={labelWrapper}>
                            <div className='col-2'>
                                <Icon style={{ color: '#e66707e8' }} size={iconSize} icon={dropbox} />
                            </div>
                            <div style={dashStyes} className='col-8 d-flex align-items-center'> <span style={numberLabel}> {itemsCount} </span>  Total Items </div>
                        </div>
                        <div className='row mt-2 border' style={labelWrapper}>
                            <div className='col-2'>
                                <Icon style={{ color: '#e66707e8' }} size={iconSize} icon={arrowRight} />
                            </div>
                            <div style={dashStyes} className='col-8 d-flex align-items-center'> <span style={numberLabel}> {itemsCountIn} </span>  Total Stock in </div>
                        </div>
                        <div className='row mt-2 border' style={labelWrapper}>
                            <div className='col-2'>
                                <Icon style={{ color: '#e66707e8' }} size={iconSize} icon={removed} />
                            </div>
                            <div style={dashStyes} className='col-8 d-flex align-items-center'> <span style={numberLabel}> {itemsCountOut} </span>  Total Stock Out </div>
                        </div>

                        <hr />
                    </div>

                    <div className='col-4'>
                        <div className='row border'>
                            <h4 style={mostMOvedStyles} >Top 5 Most Moved in</h4>
                            <table class="table">
                                <thead>
                                    <tr style={{ color: '#000' }}>
                                        <td  >#</td>
                                        <td >Item</td>
                                        <td  >Times moved</td>

                                    </tr>
                                </thead>
                                <tbody>

                                    {top5MovedIn.map((item, key) => {
                                        return <tr>
                                            <th scope="row">{key + 1}</th>
                                            <td>{item.name}</td>
                                            <td>{item.balance}</td>

                                        </tr>
                                    })}


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-4'>
                    <div className='row border '>
                        <h4 style={mostMOvedOutStyles}>Top 5 Most Moved Out</h4>
                        <table class="table">
                            <thead>
                                <tr style={{ color: '#000' }}>
                                    <td  >#</td>
                                    <td >Item</td>
                                    <td  >Times moved</td>

                                </tr>
                            </thead>
                            <tbody>
                                {top5MovedOut.map((item, key) => {
                                    return <tr>
                                        <th scope="row">{key + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.balance}</td>
                                        
                                    </tr>
                                })}


                            </tbody>
                        </table>

                    </div>
                    </div>






                </div>



            </div>



        </div>
    )
}

export default Admin