import React from 'react'

function Dashboard() {


    return (
        <div className='dashboardHome sm_txt_12 md_txt_12'  >
            <span>
                Welcome To Stock Management system
            </span>

            <div className='homeBox_anim' style={{zIndex: '13'}}>
                Box
            </div>
        </div>
    )
}

export default Dashboard
